<template>
  <router-view />
</template>

<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");

* {
  box-sizing: border-box;
}

html {
  font-family: "Roboto", sans-serif;
}

body {
  margin: 0;
  padding: 15px;
  padding-top: 45px;
  color: #ccc;

  background-repeat: no-repeat;
  background-color: rgb(10, 10, 10);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
  align-content: center;
  flex-wrap: wrap;
}
#app {
  display: flex;
  /* align-items: stretch;
  align-content: center; */
}
.hidden {
  display: none;
}
.unhide {
  display: block;
  /* animation: slide-top 0.5s ease-out forwards; */
  -webkit-animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.blurred {
  filter: blur(3px);
  transition-duration: 2s;
}

@-webkit-keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  100% {
    -webkit-transform: translateY(100px);
    transform: translateY(100px);
  }
}
@keyframes slide-bottom {
  0% {
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.colormenu-enter-from {
  opacity: 0;
  transform: translateY(-150px);

  span {
  }
}

.colormenu-enter-active {
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.colormenu-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.colormenu-leave-from {
  opacity: 1;
  transform: translateY(0px);
  filter: blur(0px);
}
.colormenu-leave-active {
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.colormenu-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  filter: blur(3px);
}

.rotateCenter {
  -webkit-animation: rotate-center 0.6s ease-in-out both;
  animation: rotate-center 0.6s ease-in-out both;
}

/* ----------------------------------------------
 * Generated by Animista on 2021-12-27 7:24:42
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-center
 * ----------------------------------------
 */
@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
