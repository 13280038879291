<template>
  <div class="row">
    <div class="images" :class="{ hiddenImages: hiddenImages }">
      <img
        class="img1000"
        :src="num1000"
        @click="hideImg"
        @dblclick="hideImgAll"
      />
      <span
        class="img100"
        :class="cssColorNames[num100]"
        @click="hideImg"
        @dblclick="hideImgAll"
      ></span>
      <img class="img10" :src="num10" @click="hideImg" @dblclick="hideImgAll" />
    </div>
    <br />
    <p
      class="number noselect"
      :class="{ hiddenNumbers: hiddenNumbers }"
      @click="hideNumbers"
      @dblclick="hideNumbersAll"
    >
      {{ numer }}
    </p>
  </div>
</template>

<script>
import { computed, ref } from "vue";

export default {
  props: ["numer"],

  setup(props) {
    const imgPath = "/img/";
    const fileFormat = ".jpg";

    const hiddenImages = ref(false);
    const hiddenNumbers = ref(false);

    let colorName = "Pink";
    let cssColorNames = [
      "FloralWhite",
      "Black",
      "SaddleBrown",
      "OliveDrab",
      "Yellow",
      "Orange",
      "Red",
      "Pink",
      "RebeccaPurple",
      "RoyalBlue",
    ];

    const num10 = computed(() => {
      if (props.numer.length >= 1) {
        // console.log(imgPath + props.numer.slice(-2) + fileFormat);
        return imgPath + props.numer.slice(-2) + fileFormat;
      }
    });

    const num100 = computed(() => {
      if (props.numer.length >= 3) {
        return props.numer.slice(-3, -2);
      }
    });

    const num1000 = computed(() => {
      if (props.numer.length == 4) {
        // console.log(imgPath + props.numer.slice(-4, -3) + fileFormat);
        return imgPath + props.numer.slice(-4, -3) + "000" + fileFormat;
      } else {
        return imgPath + "null" + fileFormat;
      }
    });

    const hideElements = (ElClassNameWithDot, toggleClassName) => {
      document
        .querySelectorAll(ElClassNameWithDot)
        .forEach((el) => el.classList.toggle(toggleClassName));

      console.log("Hidden:", ElClassNameWithDot);
    };

    const hideImg = () => {
      hiddenImages.value = !hiddenImages.value;
      // hideElements(".images", "hiddenImages");
    };
    const hideImgAll = () => {
      console.log("double clickkkkk");

      hiddenImages.value = !hiddenImages.value;
      hideElements(".images", "hiddenImages");
    };
    const hideNumbers = () => {
      hiddenNumbers.value = !hiddenNumbers.value;
      // hideElements(".number", "hiddenNumbers");
    };
    const hideNumbersAll = () => {
      hiddenNumbers.value = !hiddenNumbers.value;
      hideElements(".number", "hiddenNumbers");
    };

    return {
      num1000,
      num100,
      num10,
      colorName,
      cssColorNames,
      hideElements,
      hiddenImages,
      hiddenNumbers,
      hideImg,
      hideNumbers,
      hideImgAll,
      hideNumbersAll,
    };
  },
};
</script>

<style>
.hiddenImages {
  /*filter: blur(30px) hue-rotate(180deg); */
  filter: invert(50%);
  opacity: 0.2;
  transition-duration: 0.5s;
}

.hiddenNumbers {
  filter: blur(4px);
}
.red {
  color: red;
  font-weight: bold;
}

:root {
  --squareSize: 115px;
}

img {
  height: var(--squareSize);
  width: var(--squareSize);
  display: block;
  float: left;
}
img:hover {
  cursor: pointer;
}
p {
  text-align: center;
  justify-content: center;
  color: rgb(133, 149, 163);
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  height: 15px;
}
.container {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: row;
  /* flex-wrap: wrap; */
  /* padding: 25px; */
  background-color: rgb(10, 10, 10);
  /* margin: 0 auto -50px; */
  margin-bottom: 7px;
  margin-top: 15px;
}

.img1000 {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}
.img100 {
  height: 115px;
  min-width: var(--squareSize);
  cursor: pointer;
  display: block;
  float: left;
}
.img10 {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.FloralWhite {
  background-color: FloralWhite;
  transition: all 0.3s;
}
.Black {
  background-color: Black;
  color: white;
  transition: all 0.3s;
}
.SaddleBrown {
  background-color: SaddleBrown;
  transition: all 0.3s;
}
.OliveDrab {
  background-color: OliveDrab;
  transition: all 0.3s;
}
.Yellow {
  background-color: Yellow;
  transition: all 0.3s;
}
.Orange {
  background-color: Orange;
  transition: all 0.3s;
}
.Red {
  background-color: Red;
  transition: all 0.3s;
}
.Pink {
  background-color: Pink;
  transition: all 0.3s;
}
.RebeccaPurple {
  background-color: RebeccaPurple;
  transition: all 0.3s;
}
.RoyalBlue {
  background-color: #1099b1;
  /* background-color: RoyalBlue; */
  transition: all 0.3s;
}

p.number {
  padding: 5px;
  padding-bottom: 25px;
  z-index: 50;
  cursor: pointer;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
</style>
