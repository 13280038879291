<template>
  <div class="colorsContainer">
    <color-item
      v-for="color in colors"
      :img="color.img"
      :key="color.number"
      :item-number="color.number"
      :bg-color="color.bgcolor"
      :text-color="color.textColor"
      :item-description="color.description"
    ></color-item>
  </div>
</template>

<script>
import ColorItem from "./ColorItem.vue";

export default {
  components: {
    ColorItem,
  },
  data() {
    return {
      colors: [
        {
          number: "0",
          img: "0000.jpg",
          bgcolor: "white",
          textColor: "black",
          description: "❄️Śnieg <br>🧂 Słony  <br>Przestrzeń",
        },
        {
          number: "1",
          img: "1000.jpg",
          bgcolor: "black",
          textColor: "white",
          description:
            "Ziemia <br>Ksiądz <br>Limuzyna <br>Stoi jak zimny pomnik <br>Zwęglony",
        },
        {
          number: "2",
          img: "2000.jpg",
          bgcolor: "SaddleBrown",
          textColor: "white",
          description: "Kora <br>🍫Czekolada <br>👨🏿Murzyn ",
        },
        {
          number: "3",
          img: "3000.jpg",
          bgcolor: "OliveDrab",
          textColor: "#FFF",
          description:
            "Trawa <br>Mech <br>Relaks <br>Leży i relaksuje się<br> Upalony",
        },
        {
          number: "4",
          img: "4000.jpg",
          bgcolor: "Yellow",
          textColor: "black",
          description: "☀️Słońce <br>Azjata <br>Siki<br> Młody <br>Trzęsie się",
        },
        {
          number: "5",
          img: "5000.jpg",
          bgcolor: "orange",
          textColor: "black",
          description:
            "🍊Zapach pomarańczy <br>Dojrzewa <br>Rośnie <br>Pulchnie <br>Pulsuje",
        },
        {
          number: "6",
          img: "6000.jpg",
          bgcolor: "red",
          textColor: "white",
          description:
            "Dojrzały <br>🌶️Pikantny <br>Agresywny aż leci para z nosa <br>🩸Krwawy",
        },
        {
          number: "7",
          img: "7000.jpg",
          bgcolor: "pink",
          textColor: "black",
          description: "Hello Kitty<br>Uprawia sex",
        },
        {
          number: "8",
          img: "8000.jpg",
          bgcolor: "RebeccaPurple",
          textColor: "white",
          description: "Impreza <br>Tańczy w światłach dyskotekowych",
        },
        {
          number: "9",
          img: "9000.jpg",
          // bgcolor: "#10B1AD",
          bgcolor: "#1099b1",
          textColor: "black",
          description:
            "Niebo. <br>🌊Woda. <br>Lata na niebie. <br>Pod woda pływa",
        },
        {
          number: "10",
          img: "null.jpg",
          bgcolor: "0a0a0a",
          textColor: "black",
          description: "",
        },
      ],
    };
  },
};
</script>

<style>
.colorsContainer {
  z-index: 99;
  position: absolute;
  top: 0px;
  left: 0px;
  margin: 0px;
  /* display: block; */
  /* justify-content: left; */
  background-color: rgb(10, 10, 10);
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>
