<template>
  <div id="navigation">
    <router-link class="navBtn" :to="{ name: 'Generate' }">☎️</router-link>

    <div class="navBtn" @click="losowanieBtn()">🎲</div>
    <div class="navBtn" @click="toggleColorLegend">🌈</div>
  </div>
</template>

<script>
export default {
  props: ["numer"],

  setup(props, context) {
    const losowanieBtn = () => {
      context.emit("losowanieBtnEmit");
      // console.log(props.numer);
    };

    const toggleColorLegend = () => {
      console.log("toggle-color-legend");
      context.emit("toggle-color-legend");
      scrollToTop();
    };

    const scrollToTop = () => {
      window.scrollTo(0, 0);
    };

    return { losowanieBtn, toggleColorLegend };
  },
};
</script>

<style>
#navigation {
  z-index: 100;
  position: fixed;
  bottom: 20px;
  border-radius: 500px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border-radius: 100px;
  padding: 5px 10px;
}

#navigation .navBtn {
  /* float: left; */
  display: flex;
  line-height: 44px;
  margin-left: 15px;
  margin-right: 15px;
  font-size: 24px;
  cursor: pointer;
  text-decoration: none;
}
</style>
