<template>
  <div>
    <div v-for="(numer, index) in numeryall.slice().reverse()" :key="index">
      <SingleRow :numer="numer" />
    </div>
  </div>
</template>

<script>
import SingleRow from "./SingleRow.vue";
export default {
  props: ["numeryall"],
  components: { SingleRow },

  setup(props) {
    return {};
  },
};
</script>

<style></style>
