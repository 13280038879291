<template>
  <div ref="imageRowContainer">
    <!-- <button @click="losowanieNumeru">wylosuj numer {{ numer }}</button>

    <br /><br /> -->

    <ImageRow :numeryall="wylosowaneNumery" />
    <a class="optionLink" @click="enterClear">clear</a>

    <Navbar
      @losowanieBtnEmit="losowanieNumeru"
      @toggle-color-legend="toggleColorLegend"
    />
    <transition name="colormenu">
      <colors-legend v-if="colorLegendVisible"></colors-legend>
    </transition>
  </div>
</template>

<script>
import { onUpdated, ref, watch } from "vue";
import { useRouter, useRoute } from "vue-router";
import Navbar from "../components/Navbar.vue";
import ImageRow from "../components/ImageRows.vue";
import ColorsLegend from "../components/ColorsLegend.vue";

export default {
  components: { Navbar, ImageRow, ColorsLegend },

  setup() {
    const router = useRouter();
    const route = useRoute();

    // auto-scroll to bottom of page
    const imageRowContainer = ref(null);
    onUpdated(() => {
      // console.log("updated imageRowContainer");
      // console.log(imageRowContainer.value);
      // console.log(imageRowContainer.value.scrollTop);
      // console.log(imageRowContainer.value.scrollHeight);
      // imageRowContainer.value.scrollTop = imageRowContainer.value.scrollHeight;
      // let scrollToVariable = imageRowContainer.value.scrollHeight;
      window.scrollTo(0, document.body.scrollHeight);
    });

    let numberFromUrl = ref("");
    let wylosowaneNumery = ref([]);
    let numer = ref("");
    let colorLegendVisible = ref(false);

    const losowanieNumeru = () => {
      numer.value = Math.floor(Math.random() * 10000)
        .toString()
        .padStart(4, "0");
      numberFromUrl.value += numer.value.toString();
    };

    const enterHome = (nowynumer) => {
      router.push({ name: "Home", params: { number: nowynumer } });
    };

    const enterClear = () => {
      numberFromUrl.value = "7777";
    };

    const toggleColorLegend = () => {
      colorLegendVisible.value = !colorLegendVisible.value;
    };

    watch(numberFromUrl, (n, o) => {
      if (typeof n !== "string") {
        n = n.toString();
      }

      const numer = n.match(/.{1,4}/g).reverse();
      wylosowaneNumery.value = numer;
      enterHome(numberFromUrl.value);
    });

    numberFromUrl.value = route.params.number || "7777";

    // watch(route.params.number, () => {
    //   console.log("URL zmieniony");
    //   location.reload();
    // });

    // watch: { route.params(to, from) {
    // if (to !== from){ location.reload() }
    // }
    // }

    return {
      losowanieNumeru,
      numer,
      wylosowaneNumery,
      numberFromUrl,
      enterHome,
      toggleColorLegend,
      colorLegendVisible,
      enterClear,
      imageRowContainer,
    };
  },
};
</script>

<style>
.colormenu-enter-from {
  opacity: 0;
  transform: translateY(-150px);
}

.colormenu-enter-active {
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.colormenu-enter-to {
  opacity: 1;
  transform: translateY(0px);
}

.colormenu-leave-from {
  opacity: 1;
  transform: translateY(0px);
  filter: blur(0px);
}
.colormenu-leave-active {
  transition: all 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.colormenu-leave-to {
  opacity: 0;
  transform: translateY(-30px);
  filter: blur(3px);
}
.optionLink {
  text-align: center;
  color: rgb(87, 87, 87);
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  padding-bottom: 75px;
  margin-top: 5px;
}
</style>
