<template>
  <div class="colorsItem" :style="{ backgroundColor: bgColor }">
    <div>
      <img :src="'./img/' + img" />
    </div>
    <div :style="{ color: textColor }" class="description">
      <span style="opacity: 0.4">{{ itemNumber }}</span
      ><br />
      <!-- {{ itemDescription }} -->
      <span v-html="itemDescription"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ["itemNumber", "img", "bgColor", "textColor", "itemDescription"],
};
</script>

<style scopped>
div {
  /* float: left; */
}
.colorsItem {
  display: flex;
  align-items: center;
  /* flex-direction: row; */

  /* align-items: stretch; */
  /*flex-grow: 4; */
  width: 100%;
  padding: 7px 15px 7px 7px;
  /* padding-bottom: 30px; */
}
.colorsItem img {
  border-radius: 30px;
}
p {
  text-align: left;
  /* display: block; */
  /* color: red; */
}
.description {
  padding: 10px 0px 10px 15px;
  font-size: 0.8rem;
  line-height: 1.3rem;
}
</style>
