<template>
  <div class="container2">
    <form @submit.prevent="enterHome">
      <div class="buttonIn">
        <input pattern="[0-9]*" type="text" v-model="wpisanyNumer" />
        <a @click.prevent="pasteFromClipboard" class="optionLink">paste</a>
      </div>
      <!-- <a @click="cameraCheck" class="">camera</a>

      <div class="videobg">
        <video autoplay id="video"></video>
        <canvas class="d-none"></canvas>
      </div> -->

      <a @click="enterHome" class="enterHome">Go!</a>
      <a @click="enterHomeClear" class="close">×</a>
    </form>
  </div>
</template>

<script>
import { ref, watchEffect } from "vue";
import { useRouter } from "vue-router";

export default {
  setup() {
    const router = useRouter();

    let wpisanyNumer = ref("");
    let wpisanyNumerNoSpaces = ref("");

    const enterHome = () => {
      router.push({
        name: "Home",
        params: { number: wpisanyNumerNoSpaces.value },
      });
      return false;
    };
    const enterHomeClear = () => {
      router.go(-1);
    };

    const pasteFromClipboard = () => {
      navigator.clipboard
        .readText()
        .then((text) => {
          let textWithSpaces = text
            .replace(/[^\dA-Z]/g, "")
            .replace(/(.{4})/g, "$1 ")
            .trim();

          wpisanyNumer.value = textWithSpaces;
        })
        .catch((err) => {
          console.error("Failed to read clipboard contents: ", err);
        });
    };

    const wpisanyNumerWithSpacesWatch = watchEffect(() => {
      if (wpisanyNumer.value.length > 0) {
        // if (wpisanyNumer.value.length % 4 == 0) {
        //   wpisanyNumer.value += " ";
        // }
        wpisanyNumer.value = wpisanyNumer.value
          .replace(/([^\s]{4})/g, "$1 ")
          // .replace(/([\s]{,2})/g, " ")
          .replaceAll("  ", " ");
      }
      wpisanyNumerNoSpaces.value = wpisanyNumer.value.replaceAll(" ", "");
    });
    /////////////////////////// Camera

    const constraints = {
      video: {
        width: {
          min: 1280,
          ideal: 1920,
          max: 2560,
        },
        height: {
          min: 720,
          ideal: 1080,
          max: 1440,
        },
      },
    };

    const video = document.querySelector("video");
    let streamStarted = false;

    const cameraCheck = () => {
      console.log(video.value);
      if (
        "mediaDevices" in navigator &&
        "getUserMedia" in navigator.mediaDevices
      ) {
        // navigator.mediaDevices.getUserMedia({ video: true });

        startStream(constraints);
      }
    };

    const startStream = async (constraints) => {
      console.log("Let's get this party started");

      const stream = await navigator.mediaDevices.getUserMedia(constraints);
      handleStream(stream);
    };

    const handleStream = (stream) => {
      video.srcObject = stream;
      streamStarted = true;
    };

    return {
      wpisanyNumer,
      enterHome,
      enterHomeClear,
      pasteFromClipboard,
      cameraCheck,
      wpisanyNumerNoSpaces,
    };
  },
};
</script>

<style scoped>
.container2 {
  display: flex;
  flex-wrap: wrap;
  /* background-color: red; */

  height: 500px;
}
.close {
  z-index: 100;
  position: fixed;
  bottom: 50px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 44px;
  height: 44px;
  border: 1px solid #666;
  background-color: rgb(10, 10, 10);
  border-radius: 100px;
  color: #666;
  line-height: 44px;
  justify-content: center;
}
.close:hover {
  border-color: white;
  color: whitesmoke;
  cursor: pointer;
  transition-duration: 500ms;
}

.enterHome {
  z-index: 100;
  position: fixed;
  bottom: 150px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  width: 105px;
  height: 105px;
  border: 1px solid rgb(0, 255, 149);
  background-color: rgb(0, 255, 149);
  border-radius: 100px;
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 105px;
  justify-content: center;
}

.enterHome:hover {
  /* opacity: 0.5; */
  background-color: rgb(0, 165, 96);
  cursor: pointer;
  transition-duration: 00.5s;
}
input {
  height: 54px;
  width: 100%;
  padding: 15px;
  border-radius: 7px;
  border: 1px solid #666;
  background-color: rgb(10, 10, 10);
  color: white;
  font-size: 1.4rem;
  /* outline: none; */
}
button {
  height: 40px;
  padding: 7px;
  border-radius: 7px;
  color: rgb(0, 0, 0);
  border: 1px solid #666;
  background-color: rgb(0, 255, 149);
}

.buttonIn {
  width: 300px;
  position: relative;
}

.paste {
  position: absolute;
  top: 0;
  right: 0px;
  z-index: 2;

  height: 40px;

  text-transform: uppercase;
  color: rgb(0, 255, 149);
  /* background-color: rgb(0, 49, 43); */
  background-color: rgb(10, 10, 10);
  border: 1px solid #666;
  border-left: 0px;
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;

  font-size: 0.7rem;
  /* line-height: 40px; */
  padding: 11px;
  text-align: center;
  /* margin-top: -35px; */
}
.generate {
  width: 100%;
}
.videobg {
  background-color: tomato;
  width: 100%;
  height: 100%;
}
video {
  width: 100%;
  background: rgba(0, 0, 0, 0.2);
}
</style>
